import React, { useState } from 'react';
import Layout from '../components/layout';
import downArrow from '../images/icons/down-chevron.png';
import SEO from '../components/seo';
import data from "../../Content/WiseTip-AboutOurDomains.json"
import PWMarkdown from '../components/common/PWMarkdown';

const img1 = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/our-domain/about-our-domains-img1.png';
const img2 = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/our-domain/about-our-domains-img2.png';

const SmQuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/sm-quotes-group.png';
const QuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png';

const Subtitle = (props) => {
    const { data } = props;
    return (
        <div className="w3-content pw-aod-title-sub margin-top-40">
            {data}
        </div>
    )
}

const AboutOurDomains = () => {

    const [index, setIndex] = useState(-1)
    const [preIndex, setPreIndex] = useState(-1)
    const [codeIndex, setCodeIndex] = useState(-1)
    const [codePreIndex, setCodePreIndex] = useState(-1)
    const [contextHideShow, setContextHideShow] = useState(false)

    const handleHideShowDomain = (i) => {
        if (i != preIndex) {
            setIndex(i)
            setPreIndex(i)
        }
        else {
            setIndex(-1)
            setPreIndex(-1)
        }

        if (contextHideShow)
            setContextHideShow(false)
        if (codeIndex != -1) {
            setCodeIndex(-1)
            setCodePreIndex(-1)
        }
    }
    const handleHideShowWisetipCodes = (i) => {
        if (i != codePreIndex) {
            setCodeIndex(i)
            setCodePreIndex(i)
        }
        else {
            setCodeIndex(-1)
            setCodePreIndex(-1)
        }

        if (contextHideShow)
            setContextHideShow(false)
        if (index != -1) {
            setIndex(-1)
            setPreIndex(-1)
        }
    }

    const handleContextHideShow = () => {
        setContextHideShow(!contextHideShow)

        if (index != -1) {
            setIndex(-1)
            setPreIndex(-1)
        }
        if (codeIndex != -1) {
            setCodeIndex(-1)
            setCodePreIndex(-1)
        }
    }

    return (
        <div className="pw-wt-page pw-about-our-domains">
            <div className="w3-display-container pw-aod-background">
                <img loading="lazy" src={QuotesGroupIcon} className="pw-wt-top-right-texture w3-hide-small w3-hide-medium" />
                <img loading="lazy" src={SmQuotesGroupIcon} className="pw-wt-top-right-sm-texture w3-hide-medium w3-hide-large" />
                <Layout>
                <SEO
                        title="About Our Domains"
                        path='/about-our-domains'
                    />
                    <div className="w3-row pw-wt-list-container">
                        <div className="pw-wise-tip-feedback" style={{ paddingBottom: '4px' }}>
                            <div className="pw-aod-main-title w3-hide-small">{data.header}</div>
                            <div className="pw-aod-main-title w3-hide-large w3-hide-medium">ABOUT OUR DOMAINS</div>
                        </div>
                        <div className="w3-row pw-wise-tip-feedback ">
                            <div className="pw-wtf-content">
                                <div>
                                    {data.para_1}
                                </div>
                                <div className="w3-center margin-top-40">
                                    <img loading="lazy" src={img1} className="pw-aod-img1" />
                                </div>

                                <br />
                                <div>
                                    {data.para_2}
                                </div>
                                <Subtitle data={data.sub_title_1} />
                                <div className="w3-margin-top">
                                    <table className="pw-aod-table w3-table w3-hide-small">
                                        {data.age_ranges.map((item, i) => (
                                            <tr className="pw-aod-table-row" key={i} style={{ backgroundColor: item.background }}>
                                                {item.col.map((col, j) => (
                                                    <td className="pw-aod-table-col" key={j}>
                                                        {col.value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </table>
                                    <div className="pw-aod-range_table">
                                        <table className="pw-aod-table w3-table w3-hide-medium w3-hide-large" id="table1">
                                            {data.age_ranges_mob.map((item, i) => (
                                                <tr key={i} style={{ height: '48px' }}>
                                                    {item.col.map((col, j) => (
                                                        <td className="pw-aod-table-col" key={j} style={{ backgroundColor: col.background }}>
                                                            {col.value}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </table>
                                    </div>

                                    <div className="margin-top-24">
                                        {data.para_3}
                                    </div>
                                    <PWMarkdown data={data.para_4} />
                                    <div className="margin-top-24">
                                        {data.para_5}
                                    </div>
                                </div>

                                <Subtitle data={data.sub_title_2} />
                            </div>

                            <div style={{ width: '96%', marginLeft: 'auto', marginRight: 'auto' }} className="w3-margin-top w3-hide-small w3-hide-medium">
                                <table className="pw-aod-table w3-table">
                                    {data.sub_domains.map((item, i) => (
                                        <tr className="pw-aod-table-row" key={i} style={{ background: item.background, fontWeight: item.fontWeight }}>
                                            {item.col.map((col, j) => (
                                                <td className="pw-aod-table-col" style={{ width: col.width }} key={j}>
                                                    {col.value}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </table>
                                <div className="pw-aod-rotate-sub-domain">{data.title_rotate_1}</div>
                            </div>

                            <div className="pw-wtf-content">
                                <div className="pw-aod-table w3-hide-large">
                                    {data.sub_domains_mob.map((item, i) => (
                                        <div key={i}>
                                            <div className="pw-aod-table-row-mob" style={{ background: item.background, fontWeight: 'bold', padding: item.padding }}
                                                onClick={() => handleHideShowDomain(i)}>
                                                {item.value}
                                                <span className="w3-right" >
                                                    <img src={downArrow} style={{ width: '14px', marginTop: item.arrow_pos }} />
                                                </span>
                                            </div>
                                            {index == i ?
                                                (
                                                    <div>
                                                        {item.sub_items.map((obj, j) => (
                                                            <div key={j} className="pw-aod-table-row-mob" style={{ background: obj.background }}>
                                                                {obj.value}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : null}
                                        </div>
                                    ))}
                                </div>

                                <Subtitle data={data.sub_title_3} />
                                <div>
                                    {data.para_6}
                                </div>
                                <div className="margin-top-24">
                                    {data.para_7}
                                </div>
                            </div>

                            <div className="margin-top-24 w3-hide-large  w3-hide-medium">
                                <div style={{ width: '98%', margin: 'auto' }}>
                                    <table className="pw-aod-table w3-table pw-aod-tips_mob">
                                        {data.core_findings_and_tips1.map((item, i) => (
                                            <tr key={i} style={{ height: '40px' }}>
                                                {item.col.map((col, j) => (
                                                    <td className="pw-aod-table-col" style={{ background: col.background, width: col.width }} key={j}>
                                                        {col.value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </table>

                                    <table className="pw-aod-table w3-table pw-aod-tips_mob" style={{ marginTop: '3px' }}>
                                        {data.core_findings_and_tips2.map((item, i) => (
                                            <tr key={i} style={{ height: '40px' }}>
                                                {item.col.map((col, j) => (
                                                    <td className="pw-aod-table-col" style={{ background: col.background, width: col.width }} key={j}>
                                                        {col.value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </table>

                                    <div className="pw-aod-rotate-tips1">{data.title_rotate_2}</div>
                                    <div className="pw-aod-rotate-tips2">{data.title_rotate_3}</div>
                                </div>
                            </div>


                            <div className="pw-wtf-content">
                                <div className="margin-top-24  w3-hide-small">
                                    <table className="pw-aod-table w3-table" style={{ fontWeight: 'bold' }}>
                                        {data.core_findings_and_tips1.map((item, i) => (
                                            <tr key={i} style={{ height: '52px' }}>
                                                {item.col.map((col, j) => (
                                                    <td className="pw-aod-table-col" style={{ background: col.background, width: col.width }} key={j}>
                                                        {col.value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </table>

                                    <table className="pw-aod-table w3-table" style={{ fontWeight: 'bold', marginTop: '3px' }}>
                                        {data.core_findings_and_tips2.map((item, i) => (
                                            <tr key={i} style={{ height: '52px' }}>
                                                {item.col.map((col, j) => (
                                                    <td className="pw-aod-table-col" style={{ background: col.background, width: col.width }} key={j}>
                                                        {col.value}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </table>

                                    <div className="pw-aod-rotate-tips1">{data.title_rotate_2}</div>
                                    <div className="pw-aod-rotate-tips2">{data.title_rotate_3}</div>
                                </div>
                                <div className="margin-top-24">
                                    {data.para_8}
                                </div>
                                <div className="margin-top-24">
                                    {data.para_9}
                                </div>
                                <Subtitle data={data.sub_title_4} />

                                <div className="w3-center margin-top-24">
                                    <img loading="lazy" src={img2} className="pw-aod-img2" />
                                </div>

                                <div className="w3-hide-large w3-hide-medium margin-top-40">
                                    <div className="pw-aod-table" style={{ fontWeight: 'bold' }}>
                                        Domain (2 letters) and Sub-Domain (3 letters)
                                    </div>
                                    <div className="pw-aod-table w3-margin-top" style={{ fontSize: '13px' }}>
                                        {data.wisetip_codes_table1_mob.map((item, i) => (
                                            <div key={i}>
                                                <div className="pw-aod-table-row-mob" style={{ background: item.background, fontWeight: 'bold', padding: item.padding, display: 'flex' }}
                                                    onClick={() => handleHideShowWisetipCodes(i)}>

                                                    <span>{item.value}</span>
                                                    <span style={{ paddingLeft: '35px' }}>{item.code}</span>
                                                    <span style={{ marginRight: item.margin_right }}>
                                                        <img src={downArrow} style={{ width: '14px', marginLeft: '20px' }} />
                                                    </span>
                                                </div>
                                                {codeIndex == i ?
                                                    (
                                                        <table className="pw-aod-table w3-table">
                                                            {item.sub_items.map((obj, j) => (
                                                                <tr key={j} style={{ background: obj.background, height: '52px' }}>
                                                                    <td className="pw-aod-table-col_mob" style={{ width: '76%' }}>{obj.value}</td>
                                                                    <td className="pw-aod-table-col_mob" style={{ width: '24%' }}>{obj.code}</td>
                                                                </tr>
                                                            ))}
                                                        </table>
                                                    ) : null}
                                            </div>
                                        ))}

                                        {data.wisetip_codes_table3_mob.map((item, i) => (
                                            <div key={i}>
                                                <div className="pw-aod-table-row-mob w3-margin-top" style={{ background: item.background, fontWeight: 'bold' }}
                                                    onClick={() => handleContextHideShow()}>
                                                    {item.value}
                                                    <span className="w3-right">
                                                        <img src={downArrow} style={{ width: '14px' }} />
                                                    </span>
                                                </div>
                                                {contextHideShow ?
                                                    (
                                                        <div>
                                                            {item.sub_items.map((obj, j) => (
                                                                <div key={j} className="pw-aod-table-row-mob" style={{ background: obj.background }}>
                                                                    {obj.value}
                                                                    <span className="w3-right" style={{ marginRight: '32px' }}>{obj.code}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ) : null}
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>
                            <div style={{ width: '88%', marginLeft: 'auto', marginRight: 'auto' }} className="w3-hide-small">
                                <div className="w3-row" style={{ marginTop: '60px' }}>

                                    <div className="w3-col m6 s12">
                                        <div className="pw-aod-table-container">
                                            <table className="pw-aod-table w3-table">
                                                {data.wisetip_codes_table1.map((item, i) => (
                                                    <tr className="pw-aod-table-row" key={i} style={{ background: item.background }}>
                                                        {item.col.map((col, j) => (
                                                            <td className="pw-aod-table-col" style={{ textAlign: col.align, fontWeight: col.fontWeight, width: col.width }} key={j}>
                                                                {col.value}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                    <div className="w3-col m6 s12">
                                        <div className="pw-aod-table-container">
                                            <table className="pw-aod-table w3-table">
                                                {data.wisetip_codes_table2.map((item, i) => (
                                                    <tr className="pw-aod-table-row" key={i} style={{ background: item.background }}>
                                                        {item.col.map((col, j) => (
                                                            <td className="pw-aod-table-col" style={{ textAlign: col.align, fontWeight: col.fontWeight, width: col.width }} key={j}>
                                                                {col.value}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                        <div className="pw-aod-table-container">
                                            <table className="pw-aod-table w3-table" style={{ marginTop: '10px' }}>
                                                {data.wisetip_codes_table3.map((item, i) => (
                                                    <tr className="pw-aod-table-row" key={i} style={{ background: item.background }}>
                                                        {item.col.map((col, j) => (
                                                            <td className="pw-aod-table-col" style={{ textAlign: col.align, fontWeight: col.fontWeight, width: col.width }} key={j}>
                                                                {col.value}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        </div>
    )
}

export default AboutOurDomains

